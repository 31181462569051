<template>
    <a-modal
            :visible="visible"
            :width="760"
            :title='addText'
            :okButtonProps="{ props: {disabled: buttonLoading, loading: buttonLoading} }"
            cancelText="Hủy"
            @cancel="() => { $emit('cancel') }"
            @ok="handleSubmit"
    >
        <a-layout class="container">
            <div class="header">
                <div class="header-right">
                    <span>Danh mục:</span>
                    <a-select defaultValue="all" style="width: 180px; margin-left: 15px" @change="handleCateChange" :disabled="buttonLoading">
                        <a-select-option value="all">Tất cả</a-select-option>
                        <a-select-option v-for="cate in categories" :key="cate.id" :value="cate.id">
                            <a-avatar shape="square" size="small" :src="cate.featuredImage.url" />
                            <span style="margin-left: 5px">{{cate.name}}</span>
                        </a-select-option>
                    </a-select>
                </div>
                <div class="header-left">
                    <a-select :defaultValue="searchType" style="width: 70px" @change="handleSearchTypeChange" :disabled="buttonLoading">
                        <a-select-option value="key">Tên</a-select-option>
                        <a-select-option value="id">ID</a-select-option>
                    </a-select>
                    <a-input-search
                            :placeholder="searchPlaceHolder"
                            style="width: 300px; margin-left: 3px"
                            @change="(e) => {this.selectedRowKeys = [];this.search = e.target.value}"
                            :disabled="buttonLoading"
                    />
                </div>
            </div>
            <a-divider/>
            <a-table
                    :rowSelection="{selectedRowKeys: selectedRowKeys, onChange: onSelectChange, getCheckboxProps: getCheckboxProps}"
                    :columns="columns"
                    :rowKey="record => record.id"
                    :dataSource="productFilter"
                    :pagination="true"
                    :loading="loading"
                    bordered
                    size="small"
            >
                <template slot="name" slot-scope="name">
                    {{name.first}} {{name.last}}
                </template>
                <template slot="price" slot-scope="text, record">
                    {{numberWithCommas(record.price)}}đ
                </template>
                <template slot="category" slot-scope="text">
                    {{text ? text.name:"" }}
                </template>
                <template style="text-align: center" slot="featuredImage" slot-scope="text">
                    <a-avatar shape="square"  size="large" :src="text.url" />
                </template>
                <template style="text-align: center" slot="active" slot-scope="text, record">
                    <div style="text-align: center; display: flex; flex-direction: row; justify-content: center;">
                        <a-popconfirm title='Bạn có chắc chắn muốn thay đổi?' @confirm="() =>  handleActiveChange(record)">
                            <a-checkbox :checked="text"/>
                        </a-popconfirm>
                    </div>
                </template>
                <template slot="action" slot-scope="text, record">
                    <div>
                        <a-button @click="() => handleEdit(record)" icon="edit" type="default" style="margin: 5px;"/>
                    </div>
                </template>
            </a-table>
        </a-layout>
    </a-modal>
</template>

<script>
    import {RepositoryFactory} from "@/repository/RepositoryFactory";
    import { removeUnicodeChar } from "@/util/StringUtils";
    const categoryRepository = RepositoryFactory.get("category");
    const productRepository = RepositoryFactory.get("product");

    export default {
        name: "SelectStoreProducts",
        props: ['visible', 'groupId', 'ids', 'buttonLoading'],
        data() {
            return {
                selectedRowKeys: [],
                products: [],
                productCaches: [],
                search: '',
                searchType: 'id',
                loading: false,
                categories: [],
                columns: [
                    {
                        title: 'ID',
                        dataIndex: 'id',
                        key: 'id',
                        width: '3%'
                    },
                    {
                        title: 'Hình ảnh',
                        dataIndex: 'featuredImage',
                        key: 'featuredImage',
                        width: '10%',
                        scopedSlots: { customRender: 'featuredImage' },
                    },
                    {
                        title: 'Tên sản phẩm',
                        dataIndex: 'name',
                        key: 'name',
                        width: '50%'
                    },
                    {
                        title: 'Giá sản phẩm',
                        dataIndex: 'price',
                        key: 'price',
                        width: '15%',
                        scopedSlots: { customRender: 'price' },
                    },
                    {
                        title: 'Danh mục',
                        dataIndex: 'category',
                        key: 'category',
                        width: '20%',
                        scopedSlots: { customRender: 'category' },
                    }
                ]
            }
        },
        methods: {
            numberWithCommas(x) {
                return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
            },
            onSelectChange(selectedRowKeys) {
                this.selectedRowKeys = selectedRowKeys;
            },
            getCheckboxProps(record) {
                let props = {
                    disabled: this.ids.includes(record.id)
                };
                return {props};
            },
            getProducts: async function () {
                let productResponse =  await productRepository.getAll();
                if (!productResponse.data.meta.success) throw new Error("Product data" + productResponse.data.meta.externalMessage);
                this.products = productResponse.data.data;
                this.productCaches = JSON.parse(JSON.stringify(this.products));
            },
            handleCateChange(cateId) {
                this.selectedRowKeys = [];
                if (cateId === 'all') {
                    this.products = this.productCaches;
                } else {
                    this.products = this.productCaches.filter(product => product.category.id === cateId);
                }
            },
            handleSearchTypeChange(value) {
                this.search = '';
                this.searchType = value;
            },
            handleSubmit() {
                this.$emit('create', this.selectedRowKeys);
            }
        },
        computed: {
            productFilter: function() {
                if (this.searchType === 'key') {
                    return this.products.map(item => Object.assign({}, item))
                        .filter(product => product.name && removeUnicodeChar(product.name).toUpperCase().includes(removeUnicodeChar(this.search).toUpperCase()))
                }
                if (this.search.length === 0) {
                    return this.products;
                }
                let ids = this.search.split(",");
                return this.products.filter(product => ids.includes(product.id.toString()));
            },
            addText() {
                return this.selectedRowKeys.length > 0 ? 'Chọn ' + this.selectedRowKeys.length + ' sản phẩm':'Chọn sản phẩm';
            },
            searchPlaceHolder() {
                return this.searchType === 'id' ? 'Danh sách id cách nhau bởi dấu phẩy...':'Nhập tên sản phẩm để tìm kiếm...'
            }
        },
        watch: {
            visible (value) {
                if (!value) this.selectedRowKeys = [];
            }
        },
        async created() {
            try {
                this.loading = true;
                let { data } = await categoryRepository.getAll();
                if(!data.meta.success) throw new Error(data.meta.externalMessage)
                this.categories = data.data;
                await this.getProducts()
            } catch (e) {
                await this.$message.error(e.message);
            } finally {
                this.loading = false;
            }
        }
    }
</script>

<style scoped>
    .container {
        background-color: white;
    }
    .header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }
</style>