<template>
    <a-layout-content class="ProductGroupList">
        <a-breadcrumb style="margin: 16px 24px">
            <a-breadcrumb-item>
                <router-link :to="{ path: '/' }" class="nav-link">
                    Trang chủ
                </router-link>
            </a-breadcrumb-item>
            <a-breadcrumb-item>
                <router-link :to="{ path: '/groups' }" class="nav-link">
                    Danh sách
                </router-link>
            </a-breadcrumb-item>
            <a-breadcrumb-item>
                <router-link :to="{ path: '/groups' }" class="nav-link">
                    {{group ? group.name:""}}
                </router-link>
            </a-breadcrumb-item>
            <a-breadcrumb-item>{{ this.$route.name }}</a-breadcrumb-item>
        </a-breadcrumb>
        <div :style="{minHeight: 'calc(100vh - 120px)', backgroundColor: '#fff', margin: '15px', padding: '15px' }">
            <a-layout-content :style="{ padding: '0 24px' }">
                <h2 v-if="group">
                    <a-avatar shape="square" size="small" :src="group.groupIcon.url" />
                    {{group ? group.name:""}} ({{products.length}} sản phẩm)
                </h2>
                <a-divider/>
                <div class="content-header">
                    <div class="content-header__right">
                        <div class="bulk-action">
                            <span>Hành động:</span>
                            <a-popconfirm @visibleChange="handleVisibleChange" :visible="popupVisible" title='Bạn có chắc chắn muốn thực hiện?' @confirm="handleBulkActionConfirm">
                                <a-button  :disabled="!hasSelected" style="margin-left: 15px" type="primary">Xóa {{ selectedRowKeys.length }} sản phẩm</a-button>
                            </a-popconfirm>
                        </div>
                    </div>
                    <div class="content-header__left">
                        <a-button type="primary" icon="plus-circle" @click="showModalSelectProduct">Thêm sản phẩm</a-button>

                        <a-input-search
                                placeholder="Tìm kiếm..."
                                style="width: 200px; margin-left: 30px"
                                @change="(e) => this.search = e.target.value"
                        />
                    </div>
                </div>
                <a-divider />
                <a-table
                        :rowSelection="{selectedRowKeys: selectedRowKeys, onChange: onSelectChange}"
                        :columns="columns"
                        :rowKey="record => record.id"
                        :dataSource="productFilter"
                        :pagination="true"
                        :loading="loading"
                        bordered
                >
                    <template slot="name" slot-scope="name">
                        {{name.first}} {{name.last}}
                    </template>
                    <template slot="price" slot-scope="text, record">
                        {{numberWithCommas(record.price)}}đ
                    </template>
                    <template slot="category" slot-scope="text">
                        {{text ? text.name:"" }}
                    </template>
                    <template style="text-align: center" slot="featuredImage" slot-scope="text">
                        <a-avatar shape="square"  size="large" :src="text.url" />
                    </template>
                    <template slot="action" slot-scope="text, record">
                        <div>
                            <a-button @click="() => handleEdit(record)" icon="edit" type="default" style="margin: 5px;"/>
                        </div>
                    </template>
                </a-table>
            </a-layout-content>
        </div>

        <!--    Modal add new    -->
        <select-store-products
                :visible="visible"
                :groupId="groupId"
                :ids="productIds"
                :buttonLoading="buttonLoading"
                @cancel="handleCancel"
                @create="handleAddProduct"
        />
    </a-layout-content>
</template>

<script>

    import {RepositoryFactory} from "@/repository/RepositoryFactory";
    const groupRepository = RepositoryFactory.get("group");
    import { removeUnicodeChar } from "@/util/StringUtils";
    import SelectStoreProducts from "@/components/modal/SelectStoreProducts";

    export default {
        name: "ProductGroupList",
        components: { SelectStoreProducts },
        data() {
            return {
                products: [],
                productIds: [],
                group: null,
                buttonLoading: false,
                selectedRowKeys: [],
                groupId: this.$route.params.groupId,
                loading: false,
                visible: false,
                popupVisible: false,
                search: '',
                columns: [
                    {
                        title: 'ID',
                        dataIndex: 'id',
                        key: 'id',
                        width: '5%'
                    },
                    {
                        title: 'Hình ảnh',
                        dataIndex: 'featuredImage',
                        key: 'featuredImage',
                        width: '10%',
                        scopedSlots: { customRender: 'featuredImage' },
                    },
                    {
                        title: 'Tên sản phẩm',
                        dataIndex: 'name',
                        key: 'name',
                        width: '40%'
                    },
                    {
                        title: 'Giá sản phẩm',
                        dataIndex: 'price',
                        key: 'price',
                        width: '10%',
                        scopedSlots: { customRender: 'price' },
                    },
                    {
                        title: 'Danh mục',
                        dataIndex: 'category',
                        key: 'category',
                        width: '15%',
                        scopedSlots: { customRender: 'category' },
                    },
                    {
                        title: 'Thứ tự hiển thị',
                        dataIndex: 'priority',
                        key: 'priority',
                        width: '10%',
                        scopedSlots: { customRender: 'priority' },
                    },
                    {
                        title: 'Action',
                        width: '20%',
                        scopedSlots: { customRender: 'action' },
                    }
                ]
            }
        },
        computed: {
            productFilter: function() {
                return this.products.map(item => Object.assign({}, item))
                    .filter(product => product.name && removeUnicodeChar(product.name).toUpperCase().includes(removeUnicodeChar(this.search).toUpperCase()))
            },
            hasSelected() {
                return this.selectedRowKeys.length > 0;
            },
        },
        methods: {
            numberWithCommas(x) {
                return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
            },
            onSelectChange(selectedRowKeys) {
                this.selectedRowKeys = selectedRowKeys;
            },
            handleCancel() {
                this.visible = false;
            },
            async handleAddProduct(selectedIds) {
                try {
                    if (selectedIds.length === 0) return ;
                    this.loading = true;
                    this.buttonLoading = true;
                    let productGroups = selectedIds.map((id, index) => {
                        return {
                            id: {
                                productId: id,
                                groupId: this.groupId
                            },
                            priority: this.products.length + index + 1
                        }
                    });

                    let {data} = await groupRepository.addProductsToGroup({
                        groupId: this.groupId,
                        productGroups
                    });
                    if (!data.meta.success) throw new Error();
                    this.openNotification("Thêm thành công " + selectedIds.length + " sản phẩm!", "success")
                    await this.fetchProduct();
                    this.selectedRowKeys = [];
                } catch (e) {
                    // eslint-disable-next-line no-console
                    console.log(e);
                    this.openNotification("Có lỗi xảy ra! Vui lòng liên hệ team phát triển!", "error")
                } finally {
                    this.visible = false;
                    this.loading = false;
                    this.buttonLoading = false;
                }
            },
            async handleBulkActionConfirm() {
                try {
                    this.loading = true;
                    let {data} = await groupRepository.deleteProducts({
                        groupId: this.groupId,
                        productIds: this.selectedRowKeys
                    });
                    if (!data.meta.success) throw new Error();
                    this.openNotification("Xóa thành công " + this.selectedRowKeys.length + " sản phẩm!", "success");
                    this.products = this.products.filter(product => !this.selectedRowKeys.includes(product.id));
                    this.productIds = this.products.map(product => product.id);
                    this.selectedRowKeys = [];
                } catch (e) {
                    // eslint-disable-next-line no-console
                    console.log(e);
                    this.openNotification("Có lỗi xảy ra! Vui lòng liên hệ team phát triển!", "error")
                } finally {
                    this.loading = false;
                }
            },
            showModalSelectProduct() {
                this.visible = true;
            },
            async fetchProduct() {
                try {
                    this.loading = true;
                    let {data} = await groupRepository.getProductsByGroupId(this.groupId);
                    if (!data.meta.success) throw new Error();
                    this.products = data.data.sort( (a, b) => a.priority - b.priority);
                    this.productIds = this.products.map(product => product.id);
                } catch (e) {
                    // eslint-disable-next-line no-console
                    console.log(e);
                    this.openNotification("Có lỗi xảy ra! Không lấy được dữ liệu!", "error");
                } finally {
                    this.loading = false;
                }
            },
            async fetchGroupInfo() {
                try {
                    this.loading = true;
                    let {data} = await groupRepository.getGroupById(this.groupId);
                    if (!data.meta.success) throw new Error();
                    this.group = data.data;
                } catch (e) {
                    // eslint-disable-next-line no-console
                    console.log(e);
                    this.openNotification("Có lỗi xảy ra! Không lấy được dữ liệu!", "error");
                } finally {
                    this.loading = false;
                }
            },
            handleVisibleChange(visible) {
                if (visible && this.hasSelected) {
                    this.popupVisible = true;
                    return;
                }
                this.popupVisible = false;
            },
            openNotification(message, type) {
                this.$notification[type]({
                    message: "Hệ thống",
                    description: message
                });
            },
        },
        async created() {
            await this.fetchGroupInfo();
            await this.fetchProduct();
        }
    }
</script>

<style scoped>
    .content-header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }
</style>